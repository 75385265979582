@import '../../_variables.scss';

$border-color: lighten(green, 7%);

.timeline {
    position: relative;
    align-content: center;

    &__now {
        border-radius: 100%;
        display: block;
        height: 8px;
        left: 49%;
        position: absolute;
        width: 8px;
        z-index: 2;
    }

    &__timeline {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__month {
        border: 3px solid #6988C5;
        border-bottom: 0;
        color: green;
        margin: 0 auto;
        padding: 40px 7px;
        position: relative;
        width: 30px;

        &:last-child {
            border-bottom: 3px solid #6988C5;
            border-radius: 0 0 3px 3px;
        }

        &:first-child {
            border-radius: 3px 3px 0 0;
        }

        &:nth-child(odd) {
            background: #C2C9E8;
            color: black;
            font-weight: 700;
        }

        &:nth-child(even) {
            color: #6988C5;
            font-weight: 700;
        }
    }

    &__month-name {
        text-align: center;
        text-transform: uppercase;
    }

    &__event {
        border-bottom: 3px solid #6988C5;
        position: absolute;
        padding-bottom: 5px;
        width: 130px;

        &:after {
            background: #6988C5;
            border-radius: 100%;
            display: block;
            content: '';
            height: 12px;
            top: 12px;
            position: relative;
            width: 12px;
        }

        &--left {
            left: -130px;
            text-align: left;

            &:after {
                float: left;
            }
        }

        &--right {
            left: 100%;
            text-align: right;

            &:after {
                float: right;
            }
        }

        @media (min-width: $screen-xs) {
            width: 200px;

            &--left {
                left: -200px;
            }
        }

        &-title {
            color: black;
            font-size: .8rem;
            font-weight: 400;
            text-transform: uppercase;

            @media (min-width: $screen-xs) {
                font-size: 1rem;
            }
        }

        &-date {
            color: #6988C5;
            font-size: 0.8rem;

            @media (min-width: $screen-xs) {
                font-size: 1rem;
            }
        }
    }
}
