@import '../../_variables.scss';

.button {
    padding: 0.5rem 1rem;
    font-weight: bold;
    border-radius: 3px;
    border: 3px solid #B80500;
    background: none;
    color: #B80500;
    cursor: pointer;
    font-weight: 700;
    &:hover {
        color: white;
        background: #B80500;
    }
    &.small {
        font-weight: normal;
        font-size: 1rem;
    }
}

.buttonGroup > button {
    padding: 0.5rem 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 3px;
    border: 2px solid $color-logo-red;
    background: none;
    color: $color-logo-red;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    &.active {
        color: white;
        background: $color-logo-red-dark;
    }
    @media only screen and (max-width: 450px) {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
    }
}

.buttonGroup button:not(:last-child) {
    border-right: none;
    @media only screen and (max-width: $screen-xxs) {
        border-right: 2px solid $color-logo-red;
        border-bottom: none;
    }
}

.buttonGroup button:last-child {
    border-radius: 0 3px 3px 0;
    @media only screen and (max-width: $screen-xxs) {
        border-radius: 0 0 3px 3px;
    }
}

.buttonGroup button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.buttonGroup button:first-child {
    border-radius: 3px 0 0 3px;
    @media only screen and (max-width: $screen-xxs) {
        border-radius: 3px 3px 0 0;
    }
}

.buttonGroup > button:hover {
    color: white;
    background: $color-logo-red-dark;
}