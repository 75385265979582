@import '../../variables.scss';

.frivillig {
    &__frist {
        font-size: .9rem;
        margin-top: 0;
    }

    &__questions {
        list-style: none;
        margin: 5rem 0 0 0;
        padding: 0;

        @media (min-width: $screen-sm) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__question {
        @media (min-width: $screen-sm) {
            width: 45%;

            &:nth-child(even) {
                padding-left: 40px;
            }
        }
    }

    &__header {
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: bold;
        color: #FFA066; 
        text-transform: uppercase;
    }

    &__answer {
        font-size: 1.5rem;
        margin: 0 0 2rem 0;
    }
}
.frivillig__questions {
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: $screen-sm) {
        display: flex;
    }

    &__format {
        flex-grow: 1;

        @media (min-width: $screen-sm) {
            width: 100/3%;
        }
    }
}