.priceList {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.price {
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
        margin-block-end: 0;
    }
}

.soldOut > div > p, #price {
    text-decoration: line-through;
}