@import url('https://fonts.googleapis.com/css?family=BenchNine:400,700|Roboto:400,700');

html {
  font-size: 16px;
  @media only screen and (max-width: 1920px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 10px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.6rem;
  line-height: 130%;
}

h1, h2, h3, h4, h5 {
  font-family: 'BenchNine', sans-serif;
}

h1 {
  font-size: 2rem;
}