@import url(https://fonts.googleapis.com/css?family=BenchNine:400,700|Roboto:400,700);
html {
  font-size: 16px; }
  @media only screen and (max-width: 1920px) {
    html {
      font-size: 12px; } }
  @media only screen and (max-width: 1200px) {
    html {
      font-size: 10px; } }
  @media only screen and (max-width: 900px) {
    html {
      font-size: 12px; } }
  @media only screen and (max-width: 450px) {
    html {
      font-size: 10px; } }

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p {
  font-size: 1.6rem;
  line-height: 130%; }

h1, h2, h3, h4, h5 {
  font-family: 'BenchNine', sans-serif; }

h1 {
  font-size: 2rem; }

.ImageSection_section__1FFu7 {
  margin: 3rem 0;
  width: 100%;
  height: auto;
  max-height: 40rem;
  min-height: 30rem;
  overflow: hidden;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .ImageSection_section__1FFu7 {
      max-height: 15rem;
      min-height: 10rem; } }
  .ImageSection_section__1FFu7 img {
    position: absolute;
    margin: auto;
    width: 100%; }

.ImageSection_center__pm6Ee {
  top: 0;
  bottom: 0; }

.ImageSection_bottom__k1l7A {
  bottom: 0; }

.ImageSection_top__1dmqx {
  top: 0; }

.ImageSection_right__1a4Rx {
  right: 0; }

.ImageSection_left__1s7nk {
  left: 0; }

.PartnerPrices_priceList__3Zoo3 {
  width: 100%;
  display: flex;
  flex-direction: column; }

.PartnerPrices_price__2cc1o {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; }
  .PartnerPrices_price__2cc1o p {
    -webkit-margin-after: 0;
            margin-block-end: 0; }

.PartnerPrices_soldOut__2HKa8 > div > p, #PartnerPrices_price__2cc1o {
  text-decoration: line-through; }

.Frivillige_frivillig__frist__v3M4R {
  font-size: .9rem;
  margin-top: 0; }

.Frivillige_frivillig__questions__-Mw9s {
  list-style: none;
  margin: 5rem 0 0 0;
  padding: 0; }
  @media (min-width: 768px) {
    .Frivillige_frivillig__questions__-Mw9s {
      display: flex;
      flex-wrap: wrap; } }

@media (min-width: 768px) {
  .Frivillige_frivillig__question__1kc4w {
    width: 45%; }
    .Frivillige_frivillig__question__1kc4w:nth-child(even) {
      padding-left: 40px; } }

.Frivillige_frivillig__header__10cYu {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: #FFA066;
  text-transform: uppercase; }

.Frivillige_frivillig__answer__1698U {
  font-size: 1.5rem;
  margin: 0 0 2rem 0; }

.Frivillige_frivillig__questions__-Mw9s {
  list-style: none;
  margin: 0;
  padding: 0; }
  @media (min-width: 768px) {
    .Frivillige_frivillig__questions__-Mw9s {
      display: flex; } }
  .Frivillige_frivillig__questions__format__2yJV7 {
    flex-grow: 1; }
    @media (min-width: 768px) {
      .Frivillige_frivillig__questions__format__2yJV7 {
        width: 100/3%; } }

.timeline {
  position: relative;
  align-content: center; }
  .timeline__now {
    border-radius: 100%;
    display: block;
    height: 8px;
    left: 49%;
    position: absolute;
    width: 8px;
    z-index: 2; }
  .timeline__timeline {
    list-style: none;
    margin: 0;
    padding: 0; }
  .timeline__month {
    border: 3px solid #6988C5;
    border-bottom: 0;
    color: green;
    margin: 0 auto;
    padding: 40px 7px;
    position: relative;
    width: 30px; }
    .timeline__month:last-child {
      border-bottom: 3px solid #6988C5;
      border-radius: 0 0 3px 3px; }
    .timeline__month:first-child {
      border-radius: 3px 3px 0 0; }
    .timeline__month:nth-child(odd) {
      background: #C2C9E8;
      color: black;
      font-weight: 700; }
    .timeline__month:nth-child(even) {
      color: #6988C5;
      font-weight: 700; }
  .timeline__month-name {
    text-align: center;
    text-transform: uppercase; }
  .timeline__event {
    border-bottom: 3px solid #6988C5;
    position: absolute;
    padding-bottom: 5px;
    width: 130px; }
    .timeline__event:after {
      background: #6988C5;
      border-radius: 100%;
      display: block;
      content: '';
      height: 12px;
      top: 12px;
      position: relative;
      width: 12px; }
    .timeline__event--left {
      left: -130px;
      text-align: left; }
      .timeline__event--left:after {
        float: left; }
    .timeline__event--right {
      left: 100%;
      text-align: right; }
      .timeline__event--right:after {
        float: right; }
    @media (min-width: 720px) {
      .timeline__event {
        width: 200px; }
        .timeline__event--left {
          left: -200px; } }
    .timeline__event-title {
      color: black;
      font-size: .8rem;
      font-weight: 400;
      text-transform: uppercase; }
      @media (min-width: 720px) {
        .timeline__event-title {
          font-size: 1rem; } }
    .timeline__event-date {
      color: #6988C5;
      font-size: 0.8rem; }
      @media (min-width: 720px) {
        .timeline__event-date {
          font-size: 1rem; } }

.CookieDisclaimer_container__h0t5C {
  height: 100%;
  width: 100%; }

.CookieDisclaimer_disclaimer__NMScJ {
  position: fixed;
  display: flex;
  right: calc(50% - 20rem);
  padding: 1rem;
  margin-bottom: 1rem;
  max-width: 40rem;
  height: auto;
  bottom: 0;
  -webkit-animation: CookieDisclaimer_fadein__1odZd 1s;
          animation: CookieDisclaimer_fadein__1odZd 1s;
  border-radius: 6px;
  box-shadow: 0.1rem 0.1rem 0.7rem rgba(0, 0, 0, 0.4);
  background-color: white;
  align-items: center;
  z-index: 9000; }
  @media only screen and (max-width: 720px) {
    .CookieDisclaimer_disclaimer__NMScJ {
      flex-direction: column;
      align-items: flex-end;
      margin: 1rem;
      right: 0;
      max-width: none;
      width: auto; } }
  .CookieDisclaimer_disclaimer__NMScJ p {
    color: black;
    margin: 0;
    font-size: 1rem;
    margin-right: 1rem; }
    @media only screen and (max-width: 720px) {
      .CookieDisclaimer_disclaimer__NMScJ p {
        margin-bottom: 1rem; } }

@-webkit-keyframes CookieDisclaimer_fadein__1odZd {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes CookieDisclaimer_fadein__1odZd {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Button_button__2Lf63 {
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 3px;
  border: 3px solid #B80500;
  background: none;
  color: #B80500;
  cursor: pointer;
  font-weight: 700; }
  .Button_button__2Lf63:hover {
    color: white;
    background: #B80500; }
  .Button_button__2Lf63.Button_small__1WqE1 {
    font-weight: normal;
    font-size: 1rem; }

.Button_buttonGroup__STPop > button {
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 3px;
  border: 2px solid #F05350;
  background: none;
  color: #F05350;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent; }
  .Button_buttonGroup__STPop > button.Button_active__h0ecc {
    color: white;
    background: #A11C38; }
  @media only screen and (max-width: 450px) {
    .Button_buttonGroup__STPop > button {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr; } }

.Button_buttonGroup__STPop button:not(:last-child) {
  border-right: none; }
  @media only screen and (max-width: 600px) {
    .Button_buttonGroup__STPop button:not(:last-child) {
      border-right: 2px solid #F05350;
      border-bottom: none; } }

.Button_buttonGroup__STPop button:last-child {
  border-radius: 0 3px 3px 0; }
  @media only screen and (max-width: 600px) {
    .Button_buttonGroup__STPop button:last-child {
      border-radius: 0 0 3px 3px; } }

.Button_buttonGroup__STPop button:not(:first-child):not(:last-child) {
  border-radius: 0; }

.Button_buttonGroup__STPop button:first-child {
  border-radius: 3px 0 0 3px; }
  @media only screen and (max-width: 600px) {
    .Button_buttonGroup__STPop button:first-child {
      border-radius: 3px 3px 0 0; } }

.Button_buttonGroup__STPop > button:hover {
  color: white;
  background: #A11C38; }

#ProgramDetails_vimeo__1MPBT {
  height: 209px;
  width: 280px; }
  @media (min-width: 768px) {
    #ProgramDetails_vimeo__1MPBT {
      height: 380px;
      width: 678px; } }
  @media (min-width: 992px) {
    #ProgramDetails_vimeo__1MPBT {
      height: 519px;
      width: 924px; } }

.AcademyAutumn_header__ckECs {
  font-size: 3rem; }

.AcademyAutumn_academyAutumn__frist__3NGLX {
  font-size: .9rem;
  margin-top: 0; }

.AcademyAutumn_academyAutumn__questions__31-of {
  list-style: none;
  margin: 5rem 0 0 0;
  padding: 0; }
  @media (min-width: 768px) {
    .AcademyAutumn_academyAutumn__questions__31-of {
      display: flex;
      flex-wrap: wrap; } }

@media (min-width: 768px) {
  .AcademyAutumn_academyAutumn__question__OZplq {
    width: 45%; }
    .AcademyAutumn_academyAutumn__question__OZplq:nth-child(even) {
      padding-left: 40px; } }

.AcademyAutumn_academyAutumn__topHeader__cI9o_ {
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: bold;
  color: #F58646;
  text-transform: uppercase; }

.AcademyAutumn_academyAutumn__header__tbKj0 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
  color: #F58646;
  text-transform: uppercase; }

.AcademyAutumn_academyAutumn__answer__2dPZt {
  font-size: 1.5rem;
  margin: 0 0 2rem 0; }

.AcademyAutumn_academyAutumn__questions__31-of {
  list-style: none;
  margin: 0;
  padding: 0; }
  @media (min-width: 768px) {
    .AcademyAutumn_academyAutumn__questions__31-of {
      display: flex; } }
  .AcademyAutumn_academyAutumn__questions__format__1-HtN {
    flex-grow: 1; }
    @media (min-width: 768px) {
      .AcademyAutumn_academyAutumn__questions__format__1-HtN {
        width: 100/3%; } }

#Movie_youtube__Xewnl {
  height: 209px;
  width: 280px; }
  @media (min-width: 768px) {
    #Movie_youtube__Xewnl {
      height: 380px;
      width: 678px; } }
  @media (min-width: 992px) {
    #Movie_youtube__Xewnl {
      height: 519px;
      width: 924px; } }

.Mobile_badges__eODAp {
  display: flex;
  align-items: center;
  justify-content: center; }

