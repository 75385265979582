
$primary-font: 'BigJohn', sans-serif;
$secondary-font: 'SlimJoe', sans-serif;

$primary-color: #f7ae1a;

$color-logo-orange: #F58646; 
$color-logo-orange-dark: #FCAF1A;
$color-logo-red: #F05350;
$color-logo-red-dark: #A11C38;

$color-text-subsites: #000000;
$color-headers-subsites: #000000;

$screen-xxxs: 420px;
$screen-xxs: 600px;
$screen-xs: 720px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;