@import '../../_variables.scss';

.container {
    height: 100%; 
    width: 100%;
}

.disclaimer {
    position: fixed;
    display: flex;
    right: calc(50% - 20rem);
    padding: 1rem;
    margin-bottom: 1rem;
    max-width: 40rem;
    height: auto;
    bottom: 0;
    animation: fadein 1s;
    border-radius: 6px;
    box-shadow: 0.1rem 0.1rem 0.7rem rgba(0, 0, 0, 0.4);
    background-color: white;
    align-items: center;
    z-index: 9000;
    @media only screen and (max-width: $screen-xs)  {
        flex-direction: column;
        align-items: flex-end;
        margin: 1rem;
        right: 0;
        max-width: none;
        width: auto;
    }
    p {
        color: black;
        margin: 0;
        font-size: 1rem;
        margin-right: 1rem;
        @media only screen and (max-width: $screen-xs)  {
            margin-bottom: 1rem;
        }
    }
}

@keyframes fadein {
    from { 
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}